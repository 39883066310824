import axios from "../../axios";
import {
  RESERVATION_LIST_REQUEST,
  RESERVATION_LIST_SUCCESS,
  RESERVATION_LIST_FAIL,
  //
  RESERVATION_ADD_REQUEST,
  RESERVATION_ADD_SUCCESS,
  RESERVATION_ADD_FAIL,
  //
  RESERVATION_DETAIL_REQUEST,
  RESERVATION_DETAIL_SUCCESS,
  RESERVATION_DETAIL_FAIL,
  //
  RESERVATION_UPDATE_REQUEST,
  RESERVATION_UPDATE_SUCCESS,
  RESERVATION_UPDATE_FAIL,
  //
  RESERVATION_FIND_REQUEST,
  RESERVATION_FIND_SUCCESS,
  RESERVATION_FIND_FAIL,
  //
  RESERVATION_FINDTABLE_REQUEST,
  RESERVATION_FINDTABLE_SUCCESS,
  RESERVATION_FINDTABLE_FAIL,
  //
  RESERVATION_DELETE_REQUEST,
  RESERVATION_DELETE_SUCCESS,
  RESERVATION_DELETE_FAIL,
  //
  RESERVATION_CHECK_REQUEST,
  RESERVATION_CHECK_SUCCESS,
  RESERVATION_CHECK_FAIL,
  //
} from "../constants/reservationConstants";

// check
export const checkReservation = (date) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESERVATION_CHECK_REQUEST,
    });
    // var {
    //   userLogin: { userInfo },
    // } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        // Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.post(
      `/reservations/check-reservation/`,
      { date: date },
      config
    );

    dispatch({
      type: RESERVATION_CHECK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RESERVATION_CHECK_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// delete reservation
export const deleteReservation = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESERVATION_DELETE_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.delete(`/reservations/delete/${id}/`, config);

    dispatch({
      type: RESERVATION_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RESERVATION_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// find reservation table
export const findListReservationTable =
  (reservation_table, reservation_date) => async (dispatch, getState) => {
    console.log(
      `/reservations/findtable/${reservation_table}/${reservation_date}/`
    );
    try {
      dispatch({
        type: RESERVATION_FINDTABLE_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };

      const { data } = await axios.get(
        `/reservations/findtable/${reservation_table}/${reservation_date}/`,
        config
      );

      dispatch({
        type: RESERVATION_FINDTABLE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: RESERVATION_FINDTABLE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// find reservation
export const findListReservations =
  (reservation_date, reservation_status, search_client, table_type) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: RESERVATION_FIND_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };

      const { data } = await axios.get(
        `/reservations/find/?reservation_date=${reservation_date}&reservation_status=${reservation_status}&search_client=${search_client}&table_type=${table_type}`,
        config
      );

      dispatch({
        type: RESERVATION_FIND_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: RESERVATION_FIND_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// update reservation
export const updateReservation =
  (id, reservation) => async (dispatch, getState) => {
    try {
      dispatch({
        type: RESERVATION_UPDATE_REQUEST,
      });
      var {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };

      const { data } = await axios.put(
        `/reservations/update/${id}/`,
        reservation,
        config
      );

      dispatch({
        type: RESERVATION_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: RESERVATION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// detail reservation
export const detailReservation = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESERVATION_DETAIL_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    //
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    //
    const { data } = await axios.get(`/reservations/detail/${id}/`, config);

    dispatch({
      type: RESERVATION_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RESERVATION_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

// add new client
export const addNewReservation =
  (reservation) => async (dispatch, getState) => {
    try {
      dispatch({
        type: RESERVATION_ADD_REQUEST,
      });
      // var {
      //   userLogin: { userInfo },
      // } = getState();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          // Authorization: `Bearer ${userInfo.access}`,
        },
      };
      const { data } = await axios.post(
        `/reservations/add-confirm/`,
        reservation,
        config
      );

      dispatch({
        type: RESERVATION_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: RESERVATION_ADD_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.detail,
      });
    }
  };

// get list cars
export const getListReservations = (page) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESERVATION_LIST_REQUEST,
    });
    var {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.access}`,
      },
    };
    const { data } = await axios.get(`/reservations/?page=${page}`, config);

    dispatch({
      type: RESERVATION_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RESERVATION_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};
