import React, { useEffect, useRef, useState } from "react";

import logoEden from "../../images/icon/logo-eden.jpg";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InputModel from "../../components/InputModel";
import LayoutSection from "../../components/LayoutSection";
import { TABLES, TABLETYPE } from "../../constants";
import {
  addNewReservation,
  checkReservation,
} from "../../redux/actions/reservationActions";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";

function ConfirmScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { type, date } = useParams();

  //
  const [reservationDate, setReservationDate] = useState("");
  const [reservationDateError, setReservationDateError] = useState("");

  const [reservationTime, setReservationTime] = useState("");
  const [reservationTimeError, setReservationTimeError] = useState("");

  const [reservationPax, setReservationPax] = useState(1);
  const [reservationPaxError, setReservationPaxError] = useState("");

  const [reservationTypeTable, setReservationTypeTable] = useState("");
  const [reservationTypeTableError, setReservationTypeTableError] =
    useState("");

  const [reservationTable, setReservationTable] = useState("");
  const [reservationTableError, setReservationTableError] = useState("");

  const [prescriber, setPrescriber] = useState("");
  const [prescriberError, setPrescriberError] = useState("");

  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState("");

  const [status, setStatus] = useState("RESERVED");
  const [statusError, setStatusError] = useState("");

  const [aCompte, setACompte] = useState(0);
  const [aCompteError, setACompteError] = useState("");

  const [offert, setOffert] = useState("");
  const [offertError, setOffertError] = useState("");

  const [occassion, setOccassion] = useState("");
  const [occassionError, setOccassionError] = useState("");

  // client
  const [reservationClient, setReservationClient] = useState("");
  const [reservationClientError, setReservationClientError] = useState("");

  const [isClientExist, setIsClientExist] = useState(false);
  const [isClientExistError, setIsClientExistError] = useState("");

  const [firstName, setFirstName] = useState("");
  const [errorFirstName, setErrorFirstName] = useState("");

  const [lastName, setLastName] = useState("");
  const [errorLastName, setErrorLastName] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [phone, setPhone] = useState("");
  const [errorPhone, setErrorPhone] = useState("");

  const [otherPhone, setOtherPhone] = useState("");
  const [otherPhoneError, setOtherPhoneError] = useState("");

  const [dateNaissance, setDateNaissance] = useState("");
  const [errorDateNaissance, setErrorDateNaissance] = useState("");

  const [langue, setLangue] = useState("");
  const [langueError, setLangueError] = useState("");

  const [statusClient, setStatusClient] = useState("");
  const [statusClientError, setStatusClientError] = useState("");

  const [typeError, setTypeError] = useState("");

  //

  const [isAddReservation, setIsAddReservation] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");
  //

  const reservationCheck = useSelector((state) => state.checkReservation);
  const {
    loadingReservationCheck,
    successReservationCheck,
    errorReservationCheck,
    isFirstVip,
    isTwoVip,
    isBack,
    isDance,
  } = reservationCheck;

  const addReservation = useSelector((state) => state.createNewReservation);
  const { loadingReservationAdd, errorReservationAdd, successReservationAdd } =
    addReservation;

  const isValidDateAndAfterToday = (date) => {
    date = new Date(date);
    console.log(date instanceof Date);
    console.log(isNaN(date));
    if (!(date instanceof Date) || isNaN(date)) {
      console.log("here " + date);
      return false;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Ensure today is at midnight to only compare dates
    console.log("here  2 " + date + " ;; " + (date > today));
    return date > today;
  };

  useEffect(() => {
    if (
      !TABLETYPE.includes(type) ||
      date === "" ||
      !isValidDateAndAfterToday(date)
    ) {
      navigate("/");
    } else {
      dispatch(checkReservation(date));
      setReservationDate(date);
      setReservationTypeTable(type);
    }
  }, [navigate, type, date]);

  useEffect(() => {
    if (successReservationCheck) {
      console.log(type);
      if (type === "VIP 1") {
        if (!isFirstVip) {
          navigate("/");
        }
      } else if (type === "VIP 2") {
        if (!isTwoVip) {
          navigate("/");
        }
      } else if (type === "BACK STAGE") {
        if (!isBack) {
          navigate("/");
        }
      } else if (type === "DANCE FLOOR") {
        if (!isDance) {
          navigate("/");
        }
      }
    }
  }, [successReservationCheck]);

  const [isOpenNav, setIsOpenNav] = useState(false);

  return (
    <div className=" w-screen min-h-screen  ">
      <nav className="bg-[#183a29]  fixed w-full  top-0 start-0 border-b border-[#183a29] z-99999">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a
            href="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img src={logoEden} className="h-16" alt="Flowbite Logo" />
          </a>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            onClick={() => setIsOpenNav(!isOpenNav)}
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className={`${
              isOpenNav ? "" : "hidden"
            } w-full md:block md:w-auto z-99999`}
            id="navbar-default"
          >
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border  rounded-lg  md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 ">
              <li>
                <a
                  href="https://booking.edenclubmarrakech.com/"
                  className="block py-2 px-3 text-white  rounded  md:p-0 hover:text-[#9e804f]"
                  aria-current="page"
                >
                  Réservations
                </a>
              </li>
              <li>
                <a
                  href="https://events.edenclubmarrakech.com/"
                  className="block py-2 px-3 text-white  rounded  md:p-0 hover:text-[#9e804f]"
                >
                  Evenements
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block py-2 px-3 text-white  rounded  md:p-0 hover:text-[#9e804f]"
                >
                  Carte
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block py-2 px-3 text-white  rounded  md:p-0 hover:text-[#9e804f]"
                >
                  Visite 360
                </a>
              </li>
              <li>
                <a
                  href="https://booking.edenclubmarrakech.com/contact/"
                  className="block py-2 px-3 text-white  rounded  md:p-0 hover:text-[#9e804f]"
                >
                  Contactez-nous
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="pt-30 container mx-auto px-3">
        <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
          <h4 className=" uppercase font-semibold ">
            Ajouter un nouveau réservation
          </h4>
        </div>
        {/*  */}
        <div className="flex md:flex-row flex-col ">
          <div className=" w-full px-1 py-1">
            <LayoutSection
              title="Réservation Information"
              styles="bg-primary text-white font-bold"
            >
              {/*  */}
              <div className="md:py-2 md:flex ">
                <InputModel
                  label="Date*"
                  type="date"
                  disabled={true}
                  placeholder=""
                  min={new Date().toISOString().split("T")[0]}
                  value={reservationDate}
                  //   onChange={(v) => setReservationDate(v.target.value)}
                  error={reservationDateError}
                />

                <InputModel
                  label="Heure*"
                  type="select"
                  placeholder=""
                  value={reservationTime}
                  onChange={(v) => setReservationTime(v.target.value)}
                  error={reservationTimeError}
                  options={[
                    {
                      label: "00:00",
                      value: "00:00:00",
                    },
                    {
                      label: "00:30",
                      value: "00:30:00",
                    },
                    {
                      label: "01:00",
                      value: "01:00:00",
                    },
                    {
                      label: "01:30",
                      value: "01:30:00",
                    },
                    {
                      label: "02:00",
                      value: "02:00:00",
                    },
                    {
                      label: "02:30",
                      value: "02:30:00",
                    },
                    {
                      label: "03:00",
                      value: "03:00:00",
                    },
                    {
                      label: "03:30",
                      value: "03:30:00",
                    },
                    {
                      label: "04:00",
                      value: "04:00:00",
                    },
                    {
                      label: "04:30",
                      value: "04:30:00",
                    },
                    {
                      label: "05:00",
                      value: "05:00:00",
                    },
                  ]}
                />
              </div>

              <div className="md:py-2 md:flex ">
                <InputModel
                  label="Pax*"
                  type="select"
                  placeholder=""
                  value={reservationPax}
                  onChange={(v) => setReservationPax(v.target.value)}
                  error={reservationPaxError}
                  options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((nb, index) => ({
                    value: nb,
                    label: nb,
                  }))}
                />
              </div>
              <div className="md:py-2 md:flex ">
                <InputModel
                  label="Type Table*"
                  type="select"
                  placeholder=""
                  disabled={true}
                  value={reservationTypeTable}
                  error={reservationTypeTableError}
                  options={TABLETYPE?.map((type, index) => ({
                    value: type,
                    label: type,
                  }))}
                />
              </div>
              <div className="md:py-2 md:flex ">
                <InputModel
                  label="Email*"
                  type="email"
                  placeholder=""
                  disabled={isClientExist}
                  value={email}
                  onChange={(v) => setEmail(v.target.value)}
                  error={emailError}
                />
              </div>
              {/* fisrt name & last name */}
              <div className="md:py-2 md:flex ">
                <InputModel
                  label="Nom*"
                  type="text"
                  placeholder=""
                  disabled={isClientExist}
                  value={firstName}
                  onChange={(v) => setFirstName(v.target.value)}
                  error={errorFirstName}
                />

                <InputModel
                  label="Prénom"
                  type="text"
                  placeholder=""
                  disabled={isClientExist}
                  value={lastName}
                  onChange={(v) => setLastName(v.target.value)}
                  error={errorLastName}
                />
              </div>
              <div className="md:py-2 md:flex ">
                <InputModel
                  label="Mobile"
                  type="text"
                  placeholder=""
                  disabled={isClientExist}
                  value={otherPhone}
                  onChange={(v) => setOtherPhone(v.target.value)}
                  error={otherPhoneError}
                />
                <InputModel
                  label="Téléphone"
                  type="text"
                  placeholder=""
                  disabled={isClientExist}
                  value={phone}
                  onChange={(v) => setPhone(v.target.value)}
                  error={errorPhone}
                />
              </div>
              {/*  */}
            </LayoutSection>
          </div>
        </div>
        <div className="my-2 flex flex-row items-center justify-end">
          <button
            onClick={() => {
              setEventType("cancel");
              setIsAddReservation(true);
            }}
            className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
          >
            Annuler
          </button>
          <button
            onClick={async () => {
              var check = true;
              setReservationDateError("");
              setReservationTimeError("");
              setReservationPaxError("");
              setReservationTypeTableError("");
              setReservationTableError("");
              setPrescriberError("");
              setNoteError("");
              setStatusError("");
              setACompteError("");
              setOffertError("");
              setOccassionError("");
              setErrorFirstName("");
              setErrorLastName("");
              setEmailError("");
              setErrorPhone("");
              setOtherPhoneError("");
              setErrorDateNaissance("");
              setLangueError("");
              setStatusClientError("");
              setTypeError("");

              if (reservationDate === "") {
                setReservationDateError("Ce champ est requis.");
                check = false;
              }
              if (reservationTime === "") {
                setReservationTimeError("Ce champ est requis.");
                check = false;
              }
              if (reservationPax === "") {
                setReservationPaxError("Ce champ est requis.");
                check = false;
              }
              if (reservationTypeTable === "") {
                setReservationTypeTableError("Ce champ est requis.");
                check = false;
              } else if (!TABLETYPE.includes(reservationTypeTable)) {
                setReservationTypeTableError("Ce champ est requis.");
                check = false;
              }

              // client
              setIsClientExistError("");
              if (isClientExist === true && reservationClient === "") {
                setReservationClientError("Ce champ est requis.");
                check = false;
              } else {
                if (firstName === "") {
                  setErrorFirstName("Ce champ est requis.");
                  check = false;
                }
                if (email === "") {
                  setEmailError("Ce champ est requis.");
                  check = false;
                }
              }

              if (check) {
                setEventType("add");
                setIsAddReservation(true);
              } else {
                toast.error(
                  "Certains champs sont obligatoires veuillez vérifier"
                );
              }
            }}
            className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
            Réserver
          </button>
        </div>
        <ConfirmationModal
          isOpen={isAddReservation}
          message={
            eventType === "cancel"
              ? "Êtes-vous sûr de vouloir annuler cette information ?"
              : "Etes-vous sûr de vouloir confirmer cette réservation?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setIsAddReservation(false);
              setEventType("");
              setLoadEvent(false);
              navigate("/");
            } else {
              console.log("here");
              setLoadEvent(true);
              try {
                await dispatch(
                  addNewReservation({
                    client: isClientExist ? reservationClient : "",
                    reservation_date: reservationDate,
                    reservation_time: reservationTime,
                    nbr_pax: reservationPax,
                    table_type: reservationTypeTable,
                    reservation_table: reservationTable ?? "",
                    prescriber: prescriber ?? "",
                    note: note ?? "",
                    status: status ?? 1,
                    avence_payment: isNaN(parseFloat(aCompte))
                      ? 0
                      : parseFloat(aCompte) ?? 0,
                    offert: offert ?? "",
                    occasion: occassion ?? "",
                    first_name: firstName ?? "",
                    last_name: lastName ?? "",
                    date_birth: dateNaissance ?? "",
                    other_phone: otherPhone ?? "",
                    phone: phone ?? "",
                    email: email ?? "",
                    langue: langue ?? "",
                  })
                );
                setLoadEvent(false);
                setEventType("");
                setIsAddReservation(false);
                navigate("/success");
              } catch (error) {
                // Handle the error if needed, otherwise do nothing and stay on the current page
                setLoadEvent(false);
                setEventType("");
                setIsAddReservation(false);
                toast.error("La réservation a échoué, veuillez réessayer");
              }
            }
          }}
          onCancel={() => {
            setIsAddReservation(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </div>
  );
}

export default ConfirmScreen;
