import "./App.css";
import "./axios.js";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import BookingScreen from "./screens/booking/BookingScreen.js";
import ConfirmScreen from "./screens/booking/ConfirmScreen.js";
import SuccessScreen from "./screens/booking/SuccessScreen.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <BookingScreen />,
  },
  {
    path: "/confirm/:type/:date",
    element: <ConfirmScreen />,
  },
  {
    path: "/success",
    element: <SuccessScreen />,
  },
  {
    path: "*",
    element: <BookingScreen />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
