import React, { useEffect, useRef, useState } from "react";

import logoEden from "../../images/icon/logo-eden.jpg";
import planEden from "../../images/plan/plans_eden.png";
import { generateDateList } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { checkReservation } from "../../redux/actions/reservationActions";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Datepicker } from "flowbite-react";

function BookingScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [dates, setDates] = useState([]);
  const [selectDate, setSelectDate] = useState("");
  const dateListRef = useRef(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [selectPlan, setSelectPlan] = useState("");
  const [hoverPlan, setHoverPlan] = useState("");

  useEffect(() => {
    const checkScrollPosition = () => {
      if (dateListRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = dateListRef.current;
        setIsAtStart(scrollLeft === 0);
        setIsAtEnd(scrollLeft + clientWidth === scrollWidth);
      }
    };

    const ref = dateListRef.current;
    if (ref) {
      ref.addEventListener("scroll", checkScrollPosition);
      checkScrollPosition(); // Initial check
    }

    return () => {
      if (ref) {
        ref.removeEventListener("scroll", checkScrollPosition);
      }
    };
  }, []);

  useEffect(() => {
    const dateList = generateDateList(90); // Generate dates from tomorrow to 90 days
    setDates(dateList);
    setSelectDate(dateList[0]);
  }, []);

  const getFormatDate = (date) => {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const frenchFormatter = new Intl.DateTimeFormat("fr-FR", options);
    var dt = new Date(date);
    return dt;
  };

  const getMonthNameInFrench = (date) => {
    const frenchFormatter = new Intl.DateTimeFormat("fr-FR", { month: "long" });
    const monthName = frenchFormatter.format(date);
    return monthName.charAt(0).toUpperCase() + monthName.slice(1);
  };

  const scrollLeft = () => {
    if (dateListRef.current) {
      dateListRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (dateListRef.current) {
      dateListRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const reservationCheck = useSelector((state) => state.checkReservation);
  const {
    loadingReservationCheck,
    successReservationCheck,
    errorReservationCheck,
    isFirstVip,
    isTwoVip,
    isBack,
    isDance,
  } = reservationCheck;

  useEffect(() => {
    if (selectDate !== "") {
      dispatch(checkReservation(selectDate));
    }
  }, [selectDate]);

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    // Format the date as YYYY-MM-DD
    const year = tomorrow.getFullYear();
    const month = String(tomorrow.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(tomorrow.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const [isOpenNav, setIsOpenNav] = useState(false);
  return (
    <div className=" w-screen min-h-screen bg-[#183a29] ">
      <nav className="bg-[#183a29]  fixed w-full z-20 top-0 start-0 border-b border-[#183a29]">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a
            href="/"
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img src={logoEden} className="h-16" alt="Flowbite Logo" />
          </a>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            onClick={() => setIsOpenNav(!isOpenNav)}
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div
            className={`${isOpenNav ? "" : "hidden"} w-full md:block md:w-auto`}
            id="navbar-default"
          >
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border  rounded-lg  md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 ">
              <li>
                <a
                  href="https://booking.edenclubmarrakech.com/"
                  className="block py-2 px-3 text-white  rounded  md:p-0 hover:text-[#9e804f]"
                  aria-current="page"
                >
                  Réservations
                </a>
              </li>
              <li>
                <a
                  href="https://events.edenclubmarrakech.com/"
                  className="block py-2 px-3 text-white  rounded  md:p-0 hover:text-[#9e804f]"
                >
                  Evenements
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block py-2 px-3 text-white  rounded  md:p-0 hover:text-[#9e804f]"
                >
                  Carte
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="block py-2 px-3 text-white  rounded  md:p-0 hover:text-[#9e804f]"
                >
                  Visite 360
                </a>
              </li>
              <li>
                <a
                  href="https://booking.edenclubmarrakech.com/contact/"
                  className="block py-2 px-3 text-white  rounded  md:p-0 hover:text-[#9e804f]"
                >
                  Contactez-nous
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/*  */}
      <div className="container mx-auto  relative">
        <div className="pt-26"></div>
        <div className="flex md:flex-row flex-col justify-center items-center">
          <div className="md:w-1/3 w-full flex flex-col">
            {/* "VIP 1" */}
            <div
              onClick={() => {
                if (isFirstVip) {
                  setSelectPlan(selectPlan === "VIP 1" ? "" : "VIP 1");
                } else {
                }
              }}
              onMouseEnter={() => setHoverPlan("VIP 1")}
              onMouseLeave={() => setHoverPlan("")}
              className={`${
                hoverPlan === "VIP 1" ? "bg-black bg-opacity-30" : ""
              } my-2 flex flex-row justify-between items-center bg-[darkslategrey] text-white px-3 py-3 rounded ${
                !isFirstVip ? " cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <div className="flex flex-row items-center">
                <div className="mx-2">
                  {selectPlan === "VIP 1" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                      />
                    </svg>
                  )}
                </div>
                <div className="flex flex-col">
                  <div>VIP 1</div>
                  <div className="text-xs">2000 Dhs</div>
                  <div className="text-[8px]">DÉPENSES MINIMUM</div>
                </div>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </div>
            </div>

            {/* "VIP 2" */}
            <div
              onClick={() => {
                if (isTwoVip) {
                  setSelectPlan(selectPlan === "VIP 2" ? "" : "VIP 2");
                } else {
                }
              }}
              onMouseEnter={() => setHoverPlan("VIP 2")}
              onMouseLeave={() => setHoverPlan("")}
              className={`my-2 ${
                hoverPlan === "VIP 2" ? "bg-black bg-opacity-30" : ""
              }  flex flex-row justify-between items-center bg-[darkslategrey] text-white px-3 py-3 rounded ${
                !isTwoVip ? " cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <div className="flex flex-row items-center">
                <div className="mx-2">
                  {selectPlan === "VIP 2" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                      />
                    </svg>
                  )}
                </div>
                <div className="flex flex-col">
                  <div>VIP 2</div>
                  <div className="text-xs">2000 Dhs</div>
                  <div className="text-[8px]">DÉPENSES MINIMUM</div>
                </div>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </div>
            </div>
            {/* "BACK STAGE" */}
            <div
              onClick={() => {
                if (isBack) {
                  setSelectPlan(
                    selectPlan === "BACK STAGE" ? "" : "BACK STAGE"
                  );
                } else {
                }
              }}
              onMouseEnter={() => setHoverPlan("BACK STAGE")}
              onMouseLeave={() => setHoverPlan("")}
              className={`my-2  ${
                hoverPlan === "BACK STAGE" ? "bg-black bg-opacity-30" : ""
              }  flex flex-row justify-between items-center bg-[darkslategrey] text-white px-3 py-3 rounded ${
                !isBack ? " cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <div className="flex flex-row items-center">
                <div className="mx-2">
                  {selectPlan === "BACK STAGE" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                      />
                    </svg>
                  )}
                </div>
                <div className="flex flex-col">
                  <div>BACK STAGE</div>
                  <div className="text-xs">4000 Dhs</div>
                  <div className="text-[8px]">DÉPENSES MINIMUM</div>
                </div>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </div>
            </div>
            {/* "DANCE FLOOR" */}
            <div
              onClick={() => {
                if (isDance) {
                  setSelectPlan(
                    selectPlan === "DANCE FLOOR" ? "" : "DANCE FLOOR"
                  );
                } else {
                }
              }}
              onMouseEnter={() => setHoverPlan("DANCE FLOOR")}
              onMouseLeave={() => setHoverPlan("")}
              className={`my-2  ${
                hoverPlan === "DANCE FLOOR" ? "bg-black bg-opacity-30" : ""
              } flex flex-row justify-between items-center bg-[darkslategrey] text-white px-3 py-3 rounded ${
                !isDance ? " cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <div className="flex flex-row items-center">
                <div className="mx-2">
                  {selectPlan === "DANCE FLOOR" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                      />
                    </svg>
                  )}
                </div>
                <div className="flex flex-col">
                  <div>DANCE FLOOR</div>
                  <div className="text-xs">2000 Dhs</div>
                  <div className="text-[8px]">DÉPENSES MINIMUM</div>
                </div>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m8.25 4.5 7.5 7.5-7.5 7.5"
                  />
                </svg>
              </div>
            </div>
          </div>
          {/* images */}
          <div className="md:w-2/3 w-full flex flex-col">
            <div className="flex md:flex-row flex-col md:justify-between items-center ">
              <div className="relative md:max-w-sm w-full my-2">
                <input
                  datepicker
                  datepicker-autohide
                  type="date"
                  value={selectDate}
                  onChange={(v) => setSelectDate(v.target.value)}
                  min={getTomorrowDate()}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Veuillez sélectionner une date"
                />
              </div>
              <div className="">
                <button
                  onClick={() => {
                    if (selectDate !== "" && selectPlan !== "") {
                      navigate("confirm/" + selectPlan + "/" + selectDate);
                    } else {
                    }
                  }}
                  type="button"
                  className={`text-white text-xl bg-[darkslategrey]  ${
                    selectDate === "" || selectPlan === ""
                      ? "cursor-not-allowed bg-gray-400"
                      : "cursor-pointer"
                  } font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                  disabled={selectDate === "" || selectPlan === ""}
                >
                  Réserver
                </button>
              </div>
            </div>
            <div>
              <div className="overflow-y-auto py-3 hide-scrollbar ">
                <div className="flex flex-row items-center h-[30rem] w-[60rem] mx-auto bg-[#183a29] relative ">
                  {/* image vip 1 */}
                  <img
                    src={planEden}
                    alt="VIP 1 PLAN"
                    className="w-full h-full bg-contain"
                  />
                  {/* vip 1 */}
                  <div
                    onClick={() => {
                      if (isFirstVip) {
                        setSelectPlan(selectPlan === "VIP 1" ? "" : "VIP 1");
                      } else {
                      }
                    }}
                    onMouseEnter={() => setHoverPlan("VIP 1")}
                    onMouseLeave={() => setHoverPlan("")}
                    className={` ${
                      !isFirstVip ? " cursor-not-allowed" : "cursor-pointer"
                    } ${
                      selectPlan === "VIP 1"
                        ? "bg-black bg-opacity-30 border border-gray-600"
                        : ""
                    }  absolute text-white ${
                      hoverPlan === "VIP 1" ? "bg-black bg-opacity-30" : ""
                    } hover:bg-black hover:bg-opacity-30 left-16 bottom-[8rem]   w-[20rem] h-[8rem]  items-center text-center flex flex-col justify-center  text-sm`}
                  >
                    <div>VIP 1</div>
                    {selectPlan === "VIP 1" || hoverPlan === "VIP 1" ? (
                      <div>
                        <div className="text-[8px]">2000 Dhs</div>
                        <div className="text-[8px]">DÉPENSES MINIMUM</div>
                      </div>
                    ) : null}

                    {selectPlan === "VIP 1" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                        />
                      </svg>
                    )}
                  </div>
                  {/* vip 2 */}
                  <div
                    onClick={() => {
                      if (isTwoVip) {
                        setSelectPlan(selectPlan === "VIP 2" ? "" : "VIP 2");
                      } else {
                      }
                    }}
                    onMouseEnter={() => setHoverPlan("VIP 2")}
                    onMouseLeave={() => setHoverPlan("")}
                    className={`  ${
                      !isTwoVip ? " cursor-not-allowed" : "cursor-pointer"
                    } ${
                      selectPlan === "VIP 2"
                        ? "bg-black bg-opacity-30 border border-gray-600"
                        : ""
                    } ${
                      hoverPlan === "VIP 2" ? "bg-black bg-opacity-30" : ""
                    }  absolute text-white hover:bg-black hover:bg-opacity-30 right-16 bottom-[8rem]   w-[20rem] h-[8rem]  items-center text-center flex flex-col justify-center  text-sm`}
                  >
                    <div>VIP 2</div>
                    {selectPlan === "VIP 2" || hoverPlan === "VIP 2" ? (
                      <div>
                        <div className="text-[8px]">2000 Dhs</div>
                        <div className="text-[8px]">DÉPENSES MINIMUM</div>
                      </div>
                    ) : null}

                    {selectPlan === "VIP 2" && (
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                          />
                        </svg>
                      </div>
                    )}
                  </div>

                  {/* back stage  */}
                  <div
                    onClick={() => {
                      if (isBack) {
                        setSelectPlan(
                          selectPlan === "BACK STAGE" ? "" : "BACK STAGE"
                        );
                      } else {
                      }
                    }}
                    onMouseEnter={() => setHoverPlan("BACK STAGE")}
                    onMouseLeave={() => setHoverPlan("")}
                    className={`  ${
                      !isBack ? " cursor-not-allowed" : " cursor-pointer"
                    } ${
                      selectPlan === "BACK STAGE"
                        ? "bg-black bg-opacity-30 border  border-gray-600"
                        : ""
                    } ${
                      hoverPlan === "BACK STAGE" ? "bg-black bg-opacity-30" : ""
                    }  absolute text-white hover:bg-black hover:bg-opacity-30 right-[22rem] top-[3.5rem]   w-[17rem] h-[12rem]  items-center text-center flex flex-col justify-center  text-sm`}
                  >
                    <div>BACK STAGE</div>
                    {selectPlan === "BACK STAGE" ||
                    hoverPlan === "BACK STAGE" ? (
                      <div>
                        <div className="text-[8px]">4000 Dhs</div>
                        <div className="text-[8px]">DÉPENSES MINIMUM</div>
                      </div>
                    ) : null}

                    {selectPlan === "BACK STAGE" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                        />
                      </svg>
                    )}
                  </div>

                  {/* dance floor */}
                  <div
                    onClick={() => {
                      if (isDance) {
                        setSelectPlan(
                          selectPlan === "DANCE FLOOR" ? "" : "DANCE FLOOR"
                        );
                      } else {
                      }
                    }}
                    onMouseEnter={() => setHoverPlan("DANCE FLOOR")}
                    onMouseLeave={() => setHoverPlan("")}
                    className={`  ${
                      !isDance ? " cursor-not-allowed" : "cursor-pointer"
                    } ${
                      selectPlan === "DANCE FLOOR"
                        ? "bg-black bg-opacity-30 border border-gray-600"
                        : ""
                    } ${
                      hoverPlan === "DANCE FLOOR"
                        ? "bg-black bg-opacity-30"
                        : ""
                    }  absolute text-white hover:bg-black hover:bg-opacity-30 right-[22rem] bottom-[4.5rem] w-[15rem] h-[10rem]  items-center text-center flex flex-col justify-center  text-sm`}
                  >
                    <div>DANCE FLOOR</div>
                    {selectPlan === "DANCE FLOOR" ||
                    hoverPlan === "DANCE FLOOR" ? (
                      <div>
                        <div className="text-[8px]">2000 Dhs</div>
                        <div className="text-[8px]">DÉPENSES MINIMUM</div>
                      </div>
                    ) : null}
                    {selectPlan === "DANCE FLOOR" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="container mx-auto  relative">
        {/* <div className="text-white pt-26 ">Veuillez sélectionner une date</div>
        <div className="flex items-center">
          {!isAtStart && (
            <button
              onClick={scrollLeft}
              className="p-2 m-2 bg-gray-300 rounded-full"
            >
              &larr;
            </button>
          )}
          <div
            className=" flex flex-row overflow-y-auto py-3 hide-scrollbar "
            ref={dateListRef}
          >
            {dates.map((date, index) => (
              <div
                onClick={() => {
                  setSelectDate(date);
                }}
                className={` border rounded-md p-3 shadow shadow-blue-500/50   text-center mx-2 py-3 my-2 cursor-pointer ${
                  selectDate === date
                    ? "border-green-500 text-green-500 font-bold"
                    : "border-white text-white"
                }`}
                key={index}
              >
                <div className=" text-center w-25">
                  {getFormatDate(date).getDate().toString().padStart(2, "0")}
                  <br />
                  {getMonthNameInFrench(getFormatDate(date))}
                  <br />
                  {getFormatDate(date).getFullYear()}
                </div>
              </div>
            ))}
          </div>
          {!isAtEnd && (
            <button
              onClick={scrollRight}
              className="p-2 m-2 bg-gray-300 rounded-full"
            >
              &rarr;
            </button>
          )}
        </div>
        <div className="text-white pt-5 ">Veuillez sélectionner une Type</div>
         */}

        {/* <div className="overflow-y-auto py-3 hide-scrollbar ">
          <div className="flex flex-row items-center h-[30rem] w-[60rem] mx-auto bg-[#183a29] relative ">
            <img
              src={planEden}
              alt="VIP 1 PLAN"
              className="w-full h-full bg-contain"
            />
            <div
              onClick={() => {
                if (isFirstVip) {
                  setSelectPlan(selectPlan === "VIP 1" ? "" : "VIP 1");
                } else {
                }
              }}
              className={` ${
                !isFirstVip ? " cursor-not-allowed" : "cursor-pointer"
              } ${
                selectPlan === "VIP 1"
                  ? "bg-black bg-opacity-30 border border-gray-600"
                  : ""
              }  absolute text-white hover:bg-black hover:bg-opacity-30 left-16 bottom-[8rem]   w-[20rem] h-[8rem]  items-center text-center flex flex-col justify-center  text-sm`}
            >
              <div>VIP 1</div>

              {selectPlan === "VIP 1" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                  />
                </svg>
              )}
            </div>
            <div
              onClick={() => {
                if (isTwoVip) {
                  setSelectPlan(selectPlan === "VIP 2" ? "" : "VIP 2");
                } else {
                }
              }}
              className={`  ${
                !isTwoVip ? " cursor-not-allowed" : "cursor-pointer"
              } ${
                selectPlan === "VIP 2"
                  ? "bg-black bg-opacity-30 border border-gray-600"
                  : ""
              }  absolute text-white hover:bg-black hover:bg-opacity-30 right-16 bottom-[8rem]   w-[20rem] h-[8rem]  items-center text-center flex flex-col justify-center  text-sm`}
            >
              <div>VIP 2</div>

              {selectPlan === "VIP 2" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                  />
                </svg>
              )}
            </div>

            <div
              onClick={() => {
                if (isBack) {
                  setSelectPlan(
                    selectPlan === "BACK STAGE" ? "" : "BACK STAGE"
                  );
                } else {
                }
              }}
              className={`  ${
                !isBack ? " cursor-not-allowed" : " cursor-pointer"
              } ${
                selectPlan === "BACK STAGE"
                  ? "bg-black bg-opacity-30 border  border-gray-600"
                  : ""
              }  absolute text-white hover:bg-black hover:bg-opacity-30 right-[22rem] top-[3.5rem]   w-[17rem] h-[12rem]  items-center text-center flex flex-col justify-center  text-sm`}
            >
              <div>BACK STAGE</div>

              {selectPlan === "BACK STAGE" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                  />
                </svg>
              )}
            </div>

            <div
              onClick={() => {
                if (isDance) {
                  setSelectPlan(
                    selectPlan === "DANCE FLOOR" ? "" : "DANCE FLOOR"
                  );
                } else {
                }
              }}
              className={`  ${
                !isDance ? " cursor-not-allowed" : "cursor-pointer"
              } ${
                selectPlan === "DANCE FLOOR"
                  ? "bg-black bg-opacity-30 border border-gray-600"
                  : ""
              }  absolute text-white hover:bg-black hover:bg-opacity-30 right-[22rem] bottom-[4.5rem] w-[15rem] h-[10rem]  items-center text-center flex flex-col justify-center  text-sm`}
            >
              <div>DANCE FLOOR</div>
              {selectPlan === "DANCE FLOOR" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
                  />
                </svg>
              )}
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default BookingScreen;
