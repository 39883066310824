export const baseURL = "https://edenback.aynnaka.ma/api";
export const baseURLFile = "https://edenback.aynnaka.ma";

// export const baseURL = "http://192.168.209.116:8000/api";
// export const baseURLFile = "http://192.168.209.116:8000";

// export const baseURL = "http://15.188.52.23/api";
// export const baseURLFile = "http://15.188.52.23";

export const generateDateList = (days) => {
  const dateList = [];
  const today = new Date();

  for (let i = 1; i <= days; i++) {
    const futureDate = new Date();
    futureDate.setDate(today.getDate() + i);
    dateList.push(futureDate.toISOString().split("T")[0]); // Format as YYYY-MM-DD
  }

  return dateList;
};

export const ColorTable = (table, reservations) => {
  let bg = "bg-black border-black text-white";
  if (reservations.length !== 0) {
    for (const reservation of reservations) {
      console.log(reservation.reservation_table);
      if (reservation.reservation_table === table) {
        bg = ColorStatus(reservation.status);
      }
    }
  }
  return bg;
};

export const ColorStatus = (status) => {
  let bg = "bg-black border-black text-white border";

  if (status === "RESERVED") {
    bg = "bg-yellow border-black text-black border";
  } else if (status === "CHECK") {
    bg = "bg-green border-black text-black border";
  } else if (status === "NO SHOW") {
    bg = "bg-danger border-black text-black border";
  } else if (status === "CANCEL") {
    bg = "bg-black border-black text-white border";
  } else {
    bg = "bg-black border-black text-white border";
  }

  return bg;
};

export const TABLES = (type) => {
  if (type === "VIP 1") {
    return TABLEVIPF;
  } else if (type === "VIP 2") {
    return TABLEVIPT;
  } else if (type === "BACK STAGE") {
    return TABLEBACKSTAGE;
  } else if (type === "DANCE FLOOR") {
    return TABLEDONCEFLOOR;
  } else {
    return [];
  }
};

export const TABLETYPE = ["VIP 1", "VIP 2", "BACK STAGE", "DANCE FLOOR"];

export const TABLEDONCEFLOOR = [
  {
    value: "TABLE 60",
    number: 60,
    position: "bottom-[90px] left-[123px]",
  },
  {
    value: "TABLE 61",
    number: 61,
    position: "bottom-[90px] left-[309px]",
  },
  {
    value: "TABLE 62",
    number: 62,
    position: "bottom-[90px] right-[410px]",
  },
  {
    value: "TABLE 63",
    number: 63,
    position: "bottom-[90px] right-[219px]",
  },
  //
  {
    value: "TABLE 64",
    number: 64,
    position: "top-[76px] left-[84px]",
  },
  {
    value: "TABLE 65",
    number: 65,
    position: "top-[76px] left-[316px]",
  },
  {
    value: "TABLE 66",
    number: 66,
    position: "top-[76px] right-[205px]",
  },
  //
  {
    value: "TABLE 67",
    number: 67,
    position: "top-[135px] right-[286px]",
  },
  {
    value: "TABLE 68",
    number: 68,
    position: "bottom-[168px] right-[286px]",
  },
  {
    value: "TABLE 69",
    number: 69,
    position: "bottom-[187px] right-[459px]",
  },
  {
    value: "TABLE 70",
    number: 70,
    position: "top-[166px] right-[459px]",
  },
  {
    value: "TABLE 71",
    number: 71,
    position: "top-[215px] left-[282px]",
  },
  {
    value: "TABLE 72",
    number: 72,
    position: "top-[130px] left-[282px]",
  },
  {
    value: "TABLE 73",
    number: 73,
    position: "bottom-[152px] left-[280px]",
  },
];

export const TABLEBACKSTAGE = [
  {
    value: "TABLE 40",
    number: 40,
    position: "bottom-[170px] left-[90px]",
  },
  {
    value: "TABLE 41",
    number: 41,
    position: "bottom-[230px] left-[90px]",
  },
  {
    value: "TABLE 42",
    number: 42,
    position: "top-[143px] left-[90px]",
  },
  {
    value: "TABLE 43",
    number: 43,
    position: "top-[73px] left-[90px]",
  },

  {
    value: "TABLE 44",
    number: 44,
    position: "top-[73px] left-[177px]",
  },
  {
    value: "TABLE 45",
    number: 45,
    position: "top-[73px] left-[270px]",
  },
  {
    value: "TABLE 46",
    number: 46,
    position: "top-[180px] left-[270px]",
  },
  //
  {
    value: "TABLE 47",
    number: 47,
    position: "top-[182px] left-[386px]",
  },
  {
    value: "TABLE 48",
    number: 48,
    position: "top-[73px] left-[386px]",
  },
  {
    value: "TABLE 49",
    number: 49,
    position: "top-[73px] right-[430px]",
  },
  {
    value: "TABLE 50",
    number: 50,
    position: "top-[73px] right-[343px]",
  },
  {
    value: "TABLE 51",
    number: 51,
    position: "top-[182px] right-[343px]",
  },
  //
  {
    value: "TABLE 52",
    number: 52,
    position: "top-[138px] right-[258px]",
  },
  {
    value: "TABLE 53",
    number: 53,
    position: "top-[138px] right-[145px]",
  },
  {
    value: "TABLE 54",
    number: 54,
    position: "top-[237px] right-[80px]",
  },
  //
  {
    value: "TABLE 55",
    number: 55,
    position: "bottom-[66px] left-[429px]",
  },
  {
    value: "TABLE 56",
    number: 56,
    position: "bottom-[66px] right-[283px]",
  },
];

export const TABLEVIPT = [
  {
    value: "TABLE 24",
    number: 24,
    position: "bottom-[70px] left-[125px]",
  },
  {
    value: "TABLE 25",
    number: 25,
    position: "bottom-[161px] left-[124px]",
  },
  {
    value: "TABLE 26",
    number: 26,
    position: "bottom-[161px] left-[288px]",
  },
  {
    value: "TABLE 27",
    number: 27,
    position: "top-[200px] left-[288px]",
  },
  {
    value: "TABLE 28",
    number: 28,
    position: "top-[200px] left-[124px]",
  },
  {
    value: "TABLE 29",
    number: 29,
    position: "top-[98px] left-[123px]",
  },
  {
    value: "TABLE 30",
    number: 30,
    position: "top-[98px] left-[327px]",
  },
  //
  {
    value: "TABLE 31",
    number: 31,
    position: "top-[98px] right-[352px]",
  },
  {
    value: "TABLE 32",
    number: 32,
    position: "top-[98px] right-[118px]",
  },
  {
    value: "TABLE 33",
    number: 33,
    position: "top-[200px] right-[118px]",
  },
  {
    value: "TABLE 34",
    number: 34,
    position: "top-[200px] right-[285px]",
  },
  {
    value: "TABLE 35",
    number: 35,
    position: "bottom-[160px] right-[287px]",
  },
  {
    value: "TABLE 36",
    number: 36,
    position: "bottom-[160px] right-[118px]",
  },
  {
    value: "TABLE 37",
    number: 37,
    position: "bottom-[80px] right-[118px]",
  },
];

export const TABLEVIPF = [
  {
    value: "TABLE 1",
    number: 1,
    position: "bottom-[77px] right-[195px]",
  },
  {
    value: "TABLE 2",
    number: 2,
    position: "bottom-[77px] right-[114px]",
  },
  {
    value: "TABLE 3",
    number: 3,
    position: "bottom-[150px] right-[114px]",
  },
  {
    value: "TABLE 4",
    number: 4,
    position: "bottom-[205px] right-[114px]",
  },
  {
    value: "TABLE 5",
    number: 5,
    position: "top-[140px] right-[123px]",
  },
  {
    value: "TABLE 6",
    number: 6,
    position: "top-[70px] right-[126px]",
  },
  {
    value: "TABLE 7",
    number: 7,
    position: "top-[70px] right-[248px]",
  },
  {
    value: "TABLE 8",
    number: 8,
    position: "top-[140px] right-[243px]",
  },
  //
  {
    value: "TABLE 9",
    number: 9,
    position: "top-[140px] right-[353px]",
  },
  {
    value: "TABLE 10",
    number: 10,
    position: "top-[70px] right-[357px]",
  },
  {
    value: "TABLE 11",
    number: 11,
    position: "top-[70px] left-[410px]",
  },
  {
    value: "TABLE 12",
    number: 12,
    position: "top-[140px] left-[410px]",
  },
  {
    value: "TABLE 13",
    number: 13,
    position: "top-[140px] left-[125px]",
  },
  {
    value: "TABLE 14",
    number: 14,
    position: "top-[140px] left-[310px]",
  },
  {
    value: "TABLE 15",
    number: 15,
    position: "top-[70px] left-[310px]",
  },
  {
    value: "TABLE 16",
    number: 16,
    position: "top-[70px] left-[125px]",
  },
  //
  {
    value: "TABLE 18",
    number: 18,
    position: "bottom-[187px] left-[504px]",
  },
  {
    value: "TABLE 19",
    number: 19,
    position: "bottom-[73px] left-[504px]",
  },
  {
    value: "TABLE 20",
    number: 20,
    position: "bottom-[73px] left-[390px]",
  },
  {
    value: "TABLE 21",
    number: 21,
    position: "bottom-[73px] left-[255px]",
  },
  {
    value: "TABLE 22",
    number: 22,
    position: "bottom-[73px] left-[108px]",
  },
  {
    value: "TABLE 23",
    number: 23,
    position: "bottom-[190px] left-[125px]",
  },
];

export const COUNTRIES = [
  {
    title: "Afghanistan",
    value: "AF",
  },
  {
    title: "Albania",
    value: "AL",
  },
  {
    title: "Algeria",
    value: "DZ",
  },
  {
    title: "American Samoa",
    value: "AS",
  },
  {
    title: "Andorra",
    value: "AD",
  },
  {
    title: "Angola",
    value: "AO",
  },
  {
    title: "Anguilla",
    value: "AI",
  },
  {
    title: "Argentina",
    value: "AR",
  },
  {
    title: "Armenia",
    value: "AM",
  },
  {
    title: "Aruba",
    value: "AW",
  },
  {
    title: "Australia",
    value: "AU",
  },
  {
    title: "Azerbaijan",
    value: "AZ",
  },
  {
    title: "Bahamas",
    value: "BS",
  },
  {
    title: "Bahrain",
    value: "BH",
  },
  {
    title: "Bangladesh",
    value: "BD",
  },
  {
    title: "Barbados",
    value: "BB",
  },
  {
    title: "Belarus",
    value: "BY",
  },
  {
    title: "Belgium",
    value: "BE",
  },
  {
    title: "Belize",
    value: "BZ",
  },
  {
    title: "Benin",
    value: "BJ",
  },
  {
    title: "Bermuda",
    value: "BM",
  },
  {
    title: "Bhutan",
    value: "BT",
  },
  {
    title: "Bolivia",
    value: "BO",
  },
  {
    title: "Bosnia and Herzegovina",
    value: "BA",
  },
  {
    title: "Botswana",
    value: "BW",
  },
  {
    title: "Brazil",
    value: "BR",
  },
  {
    title: "British Virgin Islands",
    value: "VG",
  },
  {
    title: "Brunei",
    value: "BN",
  },
  {
    title: "Bulgaria",
    value: "BG",
  },
  {
    title: "Burkina Faso",
    value: "BF",
  },
  {
    title: "Burundi",
    value: "BI",
  },
  {
    title: "Cambodia",
    value: "KH",
  },
  {
    title: "Cameroon",
    value: "CM",
  },
  {
    title: "Canada",
    value: "CA",
  },
  {
    title: "Cape Verde",
    value: "CV",
  },
  {
    title: "Cayman Islands",
    value: "KY",
  },
  {
    title: "Central African Republic",
    value: "CF",
  },
  {
    title: "Chad",
    value: "TD",
  },
  {
    title: "Chile",
    value: "CL",
  },
  {
    title: "China",
    value: "CN",
  },
  {
    title: "Columbia",
    value: "CO",
  },
  {
    title: "Comoros",
    value: "KM",
  },
  {
    title: "Cook Islands",
    value: "CK",
  },
  {
    title: "Costa Rica",
    value: "CR",
  },
  {
    title: "Croatia",
    value: "HR",
  },
  {
    title: "Cuba",
    value: "CU",
  },
  {
    title: "Curacao",
    value: "CW",
  },
  {
    title: "Cyprus",
    value: "CY",
  },
  {
    title: "Czech Republic",
    value: "CZ",
  },
  {
    title: "Democratic Republic of the Congo",
    value: "CD",
  },
  {
    title: "Denmark",
    value: "DK",
  },
  {
    title: "Djibouti",
    value: "DJ",
  },
  {
    title: "Dominica",
    value: "DM",
  },
  {
    title: "Dominican Republic",
    value: "DO",
  },
  {
    title: "East Timor",
    value: "TL",
  },
  {
    title: "Ecuador",
    value: "EC",
  },
  {
    title: "Egypt",
    value: "EG",
  },
  {
    title: "El Salvador",
    value: "SV",
  },
  {
    title: "Eritrea",
    value: "ER",
  },
  {
    title: "Estonia",
    value: "EE",
  },
  {
    title: "Ethiopia",
    value: "ET",
  },
  {
    title: "Faroe Islands",
    value: "FO",
  },
  {
    title: "Fiji",
    value: "FJ",
  },
  {
    title: "Finland",
    value: "FI",
  },
  {
    title: "France",
    value: "FR",
  },
  {
    title: "French Polynesia",
    value: "PF",
  },
  {
    title: "Gabon",
    value: "GA",
  },
  {
    title: "Gambia",
    value: "GM",
  },
  {
    title: "Georgia",
    value: "GE",
  },
  {
    title: "Germany",
    value: "DE",
  },
  {
    title: "Ghana",
    value: "GH",
  },
  {
    title: "Greece",
    value: "GR",
  },
  {
    title: "Greenland",
    value: "GL",
  },
  {
    title: "Grenada",
    value: "GD",
  },
  {
    title: "Guam",
    value: "GU",
  },
  {
    title: "Guatemala",
    value: "GT",
  },
  {
    title: "Guernsey",
    value: "GG",
  },
  {
    title: "Guinea",
    value: "GN",
  },
  {
    title: "Guinea-Bissau",
    value: "GW",
  },
  {
    title: "Guyana",
    value: "GY",
  },
  {
    title: "Haiti",
    value: "HT",
  },
  {
    title: "Honduras",
    value: "HN",
  },
  {
    title: "Hong Kong",
    value: "HK",
  },
  {
    title: "Hungary",
    value: "HU",
  },
  {
    title: "Iceland",
    value: "IS",
  },
  {
    title: "India",
    value: "IN",
  },
  {
    title: "Indonesia",
    value: "ID",
  },
  {
    title: "Iran",
    value: "IR",
  },
  {
    title: "Iraq",
    value: "IQ",
  },
  {
    title: "Ireland",
    value: "IE",
  },
  {
    title: "Isle of Man",
    value: "IM",
  },
  {
    title: "Israel",
    value: "IL",
  },
  {
    title: "Italy",
    value: "IT",
  },
  {
    title: "Ivory Coast",
    value: "CI",
  },
  {
    title: "Jamaica",
    value: "JM",
  },
  {
    title: "Japan",
    value: "JP",
  },
  {
    title: "Jersey",
    value: "JE",
  },
  {
    title: "Jordan",
    value: "JO",
  },
  {
    title: "Kazakhstan",
    value: "KZ",
  },
  {
    title: "Kenya",
    value: "KE",
  },
  {
    title: "Kiribati",
    value: "KI",
  },
  {
    title: "Kosovo",
    value: "XK",
  },
  {
    title: "Kuwait",
    value: "KW",
  },
  {
    title: "Kyrgyzstan",
    value: "KG",
  },
  {
    title: "Laos",
    value: "LA",
  },
  {
    title: "Latvia",
    value: "LV",
  },
  {
    title: "Lebanon",
    value: "LB",
  },
  {
    title: "Lesotho",
    value: "LS",
  },
  {
    title: "Liberia",
    value: "LB",
  },
  {
    title: "Libya",
    value: "LY",
  },
  {
    title: "Liechtenstein",
    value: "LI",
  },
  {
    title: "Lithuania",
    value: "LT",
  },
  {
    title: "Luxembourg",
    value: "LU",
  },
  {
    title: "Macau",
    value: "MO",
  },
  {
    title: "Macedonia",
    value: "MK",
  },
  {
    title: "Madagascar",
    value: "MG",
  },
  {
    title: "Malawi",
    value: "MW",
  },
  {
    title: "Malaysia",
    value: "MY",
  },
  {
    title: "Maldives",
    value: "MV",
  },
  {
    title: "Mali",
    value: "ML",
  },
  {
    title: "Malta",
    value: "MT",
  },
  {
    title: "Marshall Islands",
    value: "MH",
  },
  {
    title: "Mauritania",
    value: "MR",
  },
  {
    title: "Mauritius",
    value: "MU",
  },
  {
    title: "Mayotte",
    value: "YT",
  },
  {
    title: "Mexico",
    value: "MX",
  },
  {
    title: "Micronesia",
    value: "FM",
  },
  {
    title: "Moldova",
    value: "MD",
  },
  {
    title: "Monaco",
    value: "MC",
  },
  {
    title: "Mongolia",
    value: "MN",
  },
  {
    title: "Montenegro",
    value: "ME",
  },
  {
    title: "Morocco",
    value: "MA",
  },
  {
    title: "Mozambique",
    value: "MZ",
  },
  {
    title: "Myanmar",
    value: "MM",
  },
  {
    title: "Namibia",
    value: "NA",
  },
  {
    title: "Nepal",
    value: "NP",
  },
  {
    title: "Netherlands",
    value: "NL",
  },
  {
    title: "Netherlands Antilles",
    value: "AN",
  },
  {
    title: "New Caledonia",
    value: "NC",
  },
  {
    title: "New Zealand",
    value: "NZ",
  },
  {
    title: "Nicaragua",
    value: "NI",
  },
  {
    title: "Niger",
    value: "NE",
  },
  {
    title: "Nigeria",
    value: "NG",
  },
  {
    title: "North Korea",
    value: "KP",
  },
  {
    title: "Northern Mariana Islands",
    value: "MP",
  },
  {
    title: "Norway",
    value: "NO",
  },
  {
    title: "Oman",
    value: "OM",
  },
  {
    title: "Pakistan",
    value: "PK",
  },
  {
    title: "Palestine",
    value: "PS",
  },
  {
    title: "Panama",
    value: "PA",
  },
  {
    title: "Papua New Guinea",
    value: "PG",
  },
  {
    title: "Paraguay",
    value: "PY",
  },
  {
    title: "Peru",
    value: "PE",
  },
  {
    title: "Philippines",
    value: "PH",
  },
  {
    title: "Poland",
    value: "PL",
  },
  {
    title: "Portugal",
    value: "PT",
  },
  {
    title: "Puerto Rico",
    value: "PR",
  },
  {
    title: "Qatar",
    value: "QA",
  },
  {
    title: "Republic of the Congo",
    value: "CG",
  },
  {
    title: "Reunion",
    value: "RE",
  },
  {
    title: "Romania",
    value: "RO",
  },
  {
    title: "Russia",
    value: "RU",
  },
  {
    title: "Rwanda",
    value: "RW",
  },
  {
    title: "Saint Kitts and Nevis",
    value: "KN",
  },
  {
    title: "Saint Lucia",
    value: "LC",
  },
  {
    title: "Saint Martin",
    value: "MF",
  },
  {
    title: "Saint Pierre and Miquelon",
    value: "PM",
  },
  {
    title: "Saint Vincent and the Grenadines",
    value: "VC",
  },
  {
    title: "Samoa",
    value: "WS",
  },
  {
    title: "San Marino",
    value: "SM",
  },
  {
    title: "Sao Tome and Principe",
    value: "ST",
  },
  {
    title: "Saudi Arabia",
    value: "SA",
  },
  {
    title: "Senegal",
    value: "SN",
  },
  {
    title: "Serbia",
    value: "RS",
  },
  {
    title: "Seychelles",
    value: "SC",
  },
  {
    title: "Sierra Leone",
    value: "SL",
  },
  {
    title: "Singapore",
    value: "SG",
  },
  {
    title: "Sint Maarten",
    value: "SX",
  },
  {
    title: "Slovakia",
    value: "SK",
  },
  {
    title: "Slovenia",
    value: "SI",
  },
  {
    title: "Solomon Islands",
    value: "SB",
  },
  {
    title: "Somalia",
    value: "SO",
  },
  {
    title: "South Africa",
    value: "ZA",
  },
  {
    title: "South Korea",
    value: "KR",
  },
  {
    title: "South Sudan",
    value: "SS",
  },
  {
    title: "Spain",
    value: "ES",
  },
  {
    title: "Sri Lanka",
    value: "LK",
  },
  {
    title: "Sudan",
    value: "SD",
  },
  {
    title: "Suriname",
    value: "SR",
  },
  {
    title: "Swaziland",
    value: "SZ",
  },
  {
    title: "Sweden",
    value: "SE",
  },
  {
    title: "Switzerland",
    value: "CH",
  },
  {
    title: "Syria",
    value: "SY",
  },
  {
    title: "Taiwan",
    value: "TW",
  },
  {
    title: "Tajikistan",
    value: "TJ",
  },
  {
    title: "Tanzania",
    value: "TZ",
  },
  {
    title: "Thailand",
    value: "TH",
  },
  {
    title: "Togo",
    value: "TG",
  },
  {
    title: "Tonga",
    value: "TO",
  },
  {
    title: "Trinidad and Tobago",
    value: "TT",
  },
  {
    title: "Tunisia",
    value: "TN",
  },
  {
    title: "Turkey",
    value: "TR",
  },
  {
    title: "Turkmenistan",
    value: "TM",
  },
  {
    title: "Turks and Caicos Islands",
    value: "TC",
  },
  {
    title: "Tuvalu",
    value: "TV",
  },
  {
    title: "U.S. Virgin Islands",
    value: "VI",
  },
  {
    title: "Uganda",
    value: "UG",
  },
  {
    title: "Ukraine",
    value: "UA",
  },
  {
    title: "United Arab Emirates",
    value: "AE",
  },
  {
    title: "United Kingdom",
    value: "GB",
  },
  {
    title: "United States",
    value: "US",
  },
  {
    title: "Uruguay",
    value: "UY",
  },
  {
    title: "Uzbekistan",
    value: "UZ",
  },
  {
    title: "Vanuatu",
    value: "VU",
  },
  {
    title: "Venezuela",
    value: "VE",
  },
  {
    title: "Vietnam",
    value: "VN",
  },
  {
    title: "Western Sahara",
    value: "EH",
  },
  {
    title: "Yemen",
    value: "YE",
  },
  {
    title: "Zambia",
    value: "ZM",
  },
  {
    title: "Zimbabwe",
    value: "ZW",
  },
];
