import { toast } from "react-toastify";
import {
  RESERVATION_LIST_REQUEST,
  RESERVATION_LIST_SUCCESS,
  RESERVATION_LIST_FAIL,
  //
  RESERVATION_ADD_REQUEST,
  RESERVATION_ADD_SUCCESS,
  RESERVATION_ADD_FAIL,
  //
  RESERVATION_DETAIL_REQUEST,
  RESERVATION_DETAIL_SUCCESS,
  RESERVATION_DETAIL_FAIL,
  //
  RESERVATION_UPDATE_REQUEST,
  RESERVATION_UPDATE_SUCCESS,
  RESERVATION_UPDATE_FAIL,
  //
  RESERVATION_FIND_REQUEST,
  RESERVATION_FIND_SUCCESS,
  RESERVATION_FIND_FAIL,
  //
  RESERVATION_FINDTABLE_REQUEST,
  RESERVATION_FINDTABLE_SUCCESS,
  RESERVATION_FINDTABLE_FAIL,
  //
  RESERVATION_DELETE_REQUEST,
  RESERVATION_DELETE_SUCCESS,
  RESERVATION_DELETE_FAIL,
  //
  RESERVATION_CHECK_REQUEST,
  RESERVATION_CHECK_SUCCESS,
  RESERVATION_CHECK_FAIL,
  //
} from "../constants/reservationConstants";

export const checkReservationReducer = (
  state = { isFirstVip: false, isTwoVip: false, isBack: false, isDance: false },
  action
) => {
  switch (action.type) {
    case RESERVATION_CHECK_REQUEST:
      return {
        loadingReservationCheck: true,
        isFirstVip: false,
        isTwoVip: false,
        isBack: false,
        isDance: false,
      };
    case RESERVATION_CHECK_SUCCESS:
      return {
        loadingReservationCheck: false,
        successReservationCheck: true,
        isFirstVip: action.payload.firstvip,
        isTwoVip: action.payload.twovip,
        isBack: action.payload.backstage,
        isDance: action.payload.dancefloor,
      };
    case RESERVATION_CHECK_FAIL:
      return {
        loadingReservationCheck: false,
        successReservationCheck: false,
        isFirstVip: false,
        isTwoVip: false,
        isBack: false,
        isDance: false,
        errorReservationCheck: action.payload,
      };
    default:
      return state;
  }
};

export const deleteReservationReducer = (state = {}, action) => {
  switch (action.type) {
    case RESERVATION_DELETE_REQUEST:
      return { loadingReservationDelete: true };
    case RESERVATION_DELETE_SUCCESS:
      toast.success("Cette Réservation a été supprimer avec succès");
      return {
        loadingReservationDelete: false,
        successReservationDelete: true,
      };
    case RESERVATION_DELETE_FAIL:
      toast.error(action.payload);
      return {
        loadingReservationDelete: false,
        successReservationDelete: false,
        errorReservationDelete: action.payload,
      };
    default:
      return state;
  }
};

export const findListReservationTableReducer = (
  state = { listReservationTable: [] },
  action
) => {
  switch (action.type) {
    case RESERVATION_FINDTABLE_REQUEST:
      return { loadingFindReservationTable: true, listReservationTable: [] };
    case RESERVATION_FINDTABLE_SUCCESS:
      return {
        loadingFindReservationTable: false,
        listReservationTable: action.payload.reservations,
      };
    case RESERVATION_FINDTABLE_FAIL:
      return {
        loadingFindReservationTable: false,
        errorFindReservationTable: action.payload,
      };
    default:
      return state;
  }
};

export const findListReservationReducer = (
  state = { listReservations: [] },
  action
) => {
  switch (action.type) {
    case RESERVATION_FIND_REQUEST:
      return { loadingFindReservations: true, listReservations: [] };
    case RESERVATION_FIND_SUCCESS:
      return {
        loadingFindReservations: false,
        listReservations: action.payload.reservations,
      };
    case RESERVATION_FIND_FAIL:
      return {
        loadingFindReservations: false,
        errorFindReservations: action.payload,
      };
    default:
      return state;
  }
};

export const updateReservationReducer = (state = {}, action) => {
  switch (action.type) {
    case RESERVATION_UPDATE_REQUEST:
      return { loadingReservationUpdate: true };
    case RESERVATION_UPDATE_SUCCESS:
      toast.success("Votre réservation a bien été confirmer");
      return {
        loadingReservationUpdate: false,
        successReservationUpdate: true,
      };
    case RESERVATION_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingReservationUpdate: false,
        successReservationUpdate: false,
        errorReservationUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const detailReservationReducer = (
  state = { reservation: {} },
  action
) => {
  switch (action.type) {
    case RESERVATION_DETAIL_REQUEST:
      return { loading: true };
    case RESERVATION_DETAIL_SUCCESS:
      return {
        loading: false,
        success: true,
        reservation: action.payload,
      };
    case RESERVATION_DETAIL_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const createNewReservationReducer = (state = {}, action) => {
  switch (action.type) {
    case RESERVATION_ADD_REQUEST:
      return { loadingReservationAdd: true };
    case RESERVATION_ADD_SUCCESS:
      toast.success("Votre réservation a bien été confirmer");
      return {
        loadingReservationAdd: false,
        successReservationAdd: true,
      };
    case RESERVATION_ADD_FAIL:
      console.log(action.payload);
      toast.error(action.payload);
      return {
        loadingReservationAdd: false,
        successReservationAdd: false,
        errorReservationAdd: action.payload,
      };
    default:
      return state;
  }
};

export const reservationListReducer = (
  state = { reservations: [] },
  action
) => {
  switch (action.type) {
    case RESERVATION_LIST_REQUEST:
      return { loading: true, reservations: [] };
    case RESERVATION_LIST_SUCCESS:
      return {
        loading: false,
        reservations: action.payload.reservations,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case RESERVATION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
