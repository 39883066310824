import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  createNewUserReducer,
  getDetailUserReducer,
  getProfileUserReducer,
  updateDetailUserReducer,
  updateProfileUserReducer,
  userLoginReducer,
  usersListReducer,
} from "./reducers/userReducers";

import {
  checkReservationReducer,
  createNewReservationReducer,
  deleteReservationReducer,
  detailReservationReducer,
  findListReservationReducer,
  findListReservationTableReducer,
  reservationListReducer,
  updateReservationReducer,
} from "./reducers/reservationReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  //

  //
  usersList: usersListReducer,
  createNewUser: createNewUserReducer,
  getProfileUser: getProfileUserReducer,
  updateProfileUser: updateProfileUserReducer,
  getDetailUser: getDetailUserReducer,
  updateDetailUser: updateDetailUserReducer,
  //
  checkReservation: checkReservationReducer,

  //
  reservationList: reservationListReducer,
  createNewReservation: createNewReservationReducer,
  detailReservation: detailReservationReducer,
  updateReservation: updateReservationReducer,
  findListReservation: findListReservationReducer,
  findListReservationTable: findListReservationTableReducer,
  deleteReservation: deleteReservationReducer,
  //
});

const userInfoFromStorage = localStorage.getItem("userInfoEdenBack")
  ? JSON.parse(localStorage.getItem("userInfoEdenBack"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
